import React from "react";
import {Form} from "../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";

@Form("Projekt")
export class ProjektForm extends XFormBaseModif {

    render() {
        return (
            <div>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true} labelStyle={{width:'13rem'}}/>
                        <XInputText form={this} field="nazov" label="Názov" size={50} labelStyle={{width:'13rem'}}/>
                        <XInputDate form={this} field="mesiacOd" label="Mesiac od" labelStyle={{width:'13rem'}}/>
                        <XInputDate form={this} field="mesiacDo" label="Mesiac do" labelStyle={{width:'13rem'}}/>
                        <XInputDate form={this} field="uzatvoritPoMesiac" label="Uzatvoriť po mesiac" labelStyle={{width:'13rem'}} tooltip="Nie je dovolené vytvoriť výdavok s dátumom v období po tento mesiac"/>
                        <XCheckbox form={this} field="pouzitStrucnyPopisAnglicky" label='Použiť "Popis výdavku anglicky"' labelStyle={{width:'20.5rem'}} tooltip='Používať (zobraziť vo formulári) atribút "Popis výdavku anglicky"'/>
                        <XAutoComplete form={this} assocField="mena" displayField="kod" label="Hlavná mena" labelStyle={{width:'13rem'}} sortField="id" width="7rem"/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true} labelStyle={{width:'13rem'}}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20} labelStyle={{width:'13rem'}}/>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}

XUtils.registerAppForm(<ProjektForm/>, "Projekt");
