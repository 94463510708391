import {XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {ProjektForm} from "./ProjektForm";
import {OperationType} from "@michalrakus/x-react-web-lib/XUtils";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {Utils} from "../Utils";
import {menaEUR} from "../common/enums";

export const ProjektBrowse = (props: {}) => {

    const onAddRow = () => {

        if (Utils.getCurrentOrganizacia() === null) {
            alert("Vyberte najprv organizáciu.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<ProjektForm initValues={{organizacia: Utils.getCurrentOrganizacia(), mesiacOd: null, mesiacDo: null, uzatvoritPoMesiac: null, pouzitStrucnyPopisAnglicky: false, mena: menaEUR, version: 0}} onSaveOrCancel={onSaveOrCancel}/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<ProjektForm id={selectedRow.id} onSaveOrCancel={onSaveOrCancel}/>);
    }

    // tento event listener na DB operaciu neni pekny, trebalo by nejak sikovnejsie
    const onSaveOrCancel = (object: XObject | null, objectChange: OperationType) => {
        // funkcia na spracovanie zmeny sa setuje v ProjektDropdown
        Utils.getZmenaEntityProjekt()(object, objectChange);
        // z technickych dovodov
        if (objectChange !== OperationType.Remove) {
            (props as any).openForm(null); // vratime sa do browsu
        }
    }

    return (
        <XLazyDataTable entity="Projekt" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true} onRemoveRow={onSaveOrCancel}
                        customFilter={Utils.filterCurrentOrganizacia()}
                        displayed={(props as any).displayed}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="nazov" header="Názov" width="40rem"/>
            <XLazyColumn field="mesiacOd" header="Mesiac od"/>
            <XLazyColumn field="mesiacDo" header="Mesiac do"/>
            <XLazyColumn field="uzatvoritPoMesiac" header="Uzatvoriť po mesiac"/>
            <XLazyColumn field="pouzitStrucnyPopisAnglicky" header='Použiť "Popis výdavku anglicky"'/>
            <XLazyColumn field="mena.kod" header="Hlavná mena" width="10rem"/>
        </XLazyDataTable>
    );
}
